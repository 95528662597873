<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="轮播图名称:" prop="title">
          <el-input clearable v-model="table.params.title" placeholder="请输入轮播图名称"></el-input>
        </el-form-item>
        <el-form-item label="所属区域:" prop="area_arr">
          <el-cascader
            style="width:100%;"
            v-model="table.params.area_arr"
            :options="areaArr"
            :props="{ checkStrictly: true }">
          </el-cascader>
        </el-form-item>
        <el-form-item label="展示用户:" prop="show">
          <el-select v-model="table.params.show">
            <el-option label="全部" :value="3"></el-option>
            <el-option label="需求端" :value="1"></el-option>
            <el-option label="师傅端" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="showEdit">添加</el-button>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:image="{row}">
        <el-image
          style="width: 60px; height: 60px"
          :src="row.image"
          fit="cover"
          :preview-src-list="[row.image]"></el-image>
      </template>
      <template v-slot:province="{row}">
        <span v-if="row.province == 0">全囯</span>
        <span v-else-if="row.area == 0">{{row.province_str}}{{`-${row.city_str}`}}</span>
        <span v-else>{{row.province_str}}{{`-${row.city_str}`}}{{`-${row.area_str}`}}</span>
      </template>
      <template v-slot:show="{row}">
        <span>{{toStr({1:'需求端',2:'师傅端',3:'全部'}, row.show)}}</span>
      </template>
      <template v-slot:status="{row}">
        <el-switch
          v-model="row.status"
          :active-value="1"
          :inactive-value="2"
          @change="handleStatus(row)">
        </el-switch>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" icon="el-icon-edit" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" icon="el-icon-delete" @click="remove(row)">删除</el-button>
      </template>
    </VTable>

    <edit ref="edit" :areaArr="areaArr" @refresh="getTable"></edit>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue';
import { mixinTable } from '@/mixins/table.js';
export default {
  name: 'BannerList',
  components: {
    VTable,
    Edit,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "title", label: "轮播图名称", hidden: false },
        { name: "province", label: "展示区域", hidden: false },
        { name: "image", label: "轮播图图片", hidden: false },
        { name: "show", label: "展示用户", hidden: false },
        { name: "create_time", label: "添加时间", hidden: false },
        { name: "status", label: "状态", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "200", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          title: '',
          show: '',
          province: '',
          city: '',
          area: '',
          area_arr:'',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      areaArr: [],
    }
  },
  created() {
    this.getTable();
    this.getArea();
  },
  methods: {
    getTable() {
      let _area_arr = this.table.params.area_arr;
      if(_area_arr[0] == 0) {
        this.table.params.province = -1;
        this.table.params.city = -1;
        this.table.params.area = -1;
      } else {
        this.table.params.province = _area_arr[0];
        this.table.params.city = _area_arr[1];
        this.table.params.area = _area_arr[2];
      }
      let _params = { ...this.table.params }
      delete _params.area_arr;
      this.$http.get('/admin/flex_banner/list', { params:_params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取省市区
    getArea() {
      this.$http.post('/common/district/all',{}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
          let str = {
            label: "全囯",
            pid: 0,
            value:0,
            children:[
              {
                label: "全囯",
                pid: 0,
                value:0,
              }
            ]
          }
          this.areaArr.unshift(str)
        }
      })
    },
    reset() {
      this.$refs.elFormDom.resetFields()
      this.table.params.page = 1;
      this.table.params.area_arr = '';
      this.getTable();
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.delete(row.id);
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    delete(rowid) {
      this.$http.post('/admin/flex_banner/delete', { id: rowid }).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
          this.getTable();
        } else {
          this.$message.error(res.data)
        }
      })
    },
    handleStatus(row) {
      row.status = !row.status
      this.$http.post('admin/flex_banner/operation', {id: row.id}).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.getTable()
      })
    }
  }
}
</script>